@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('../node_modules/bootstrap/dist/css/bootstrap-grid.min.css');
* {
  margin: 0;
  padding: 0;
 
}



/* Navbar styling starts here*/
.naving {
  background-color: #f2f2f2;
  padding: 10px 0px;
}
.header-arrange{
  display:flex;
  flex-direction: column !important;
  justify-content: center !important;
}
.header-fixed{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  /* height:100px; */
  width: 100%;
}
.margin-nav {
  margin-left: auto;

}
.nav-design{
  font-size:20px;
  font-weight: 500;
  font-family:Helvetica;
  margin-right: 15px;
  text-align: center;
}
.nav-design:active{
  color:chartreuse;
}
.nav-design2{
  margin-right: 0px;
}

.set-width{
  /* max-width:60%; */
}
@media screen  and (min-width: 768px){
  .logo-img {
    max-width: 70%;
    border-radius: 0px;
  }
}
@media screen and (max-width: 600px) {
  .logo-img {
    max-width: 230px;
    border-radius: 0px;
  }
}


.footer-img{
  max-width: 105%;
  border-radius: 0px;
}

.home-nav {
  text-decoration: none;
  color: black;
  /* font-size: 20px; */
  font-family: helvetica;
}

.text-size{
  font-size: 20px;
}
.para-text-size{
  font-size: 21px;
  font-family: Helvetica;
}

/* Navbar styling ends here*/

/* carousel styling starts here*/

.carousel-image >.item {
  /* width: 100% !important; */
  max-height: 4% !important;
  object-fit:contain;
}
/* .image-height{
 height:600px;
} */
/* @media (max-width: 992px) {
  .image-height {
    height:600px;
  }
}
@media (min-width: 600px) {
  .image-height {
    height:300px;
  }
} */
@media screen and (min-width: 768px) {
  .image-height {
    height:500px;
    width:95% !important;
    border-radius: 20px;
    margin-top: 20px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .image-height {
    width:95% !important;
    border-radius: 20px;
    height:230px;
    margin-top: 10px;
  }
}


@media screen and (min-width: 768px) {
  .service-height {
    width:500px !important;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .service-height {
    width:300px !important;
  }
}
@media screen and (min-width: 768px) {
  .container-margin {
    margin-top: 124px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .container-margin {
    margin-top:140px;
  }
}
@media screen and (min-width: 768px) {
  .button-width {
    width: 75%;
    border-radius: 10px;
  }
}
@media screen and (max-width: 600px) {
  .button-width {
    width: 91%;
    border-radius: 10px;
  }
}
@media screen and (min-width: 768px) {
  .franchise-button-width {
    width: 50%;
    border-radius: 10px;
  }
}
@media screen and (max-width: 600px) {
  .franchise-button-width {
    width: 91%;
    border-radius: 10px;
  }
}
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .display-image {
    display:block !important;
  }
}
@media screen and (min-width: 768px) {
  .display-image {
    display:none !important;
  }
}

@media screen and (max-width: 600px) {
  .android-download {
    height: 60px;
    margin-top: 18px;
    margin-right: 15;
    width: 130px;
  }
}
@media screen and (min-width: 768px) {
  .android-download {
    height: 68px;
    margin-top: 18px;
    margin-right: 15px;
    width: 160px;
  }
}
@media screen and (max-width: 600px) {
  .ios-download {
    height: 49px;
     margin-top: 25px;
    width: 125px;
    border-radius: 10px;
  }
}
@media screen and (min-width: 768px) {
  .ios-download {
    height: 55px;
    margin-top: 25px;
   width: 147px
  }
}
/* On screens that are 600px or less, set the background color to olive */


/* carousel styling ends here*/

/* home aboutus styling starts here*/
/* .aboutus-style {
  background-color: #f2f2f2;
  text-align: center;
  height: auto;

} */
.arrange-divs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-arrange{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 45px;
}
.min-container {

  padding: 50px 50px;
  border-radius: 10px;
 
}
.min-container2{
  border-radius: 10px;
}

.about-button {
  background-color: #408140;
  font-weight: 400;
  font-size: 20px;
  font-family: helvetica;
  padding: 10px 10px;
  border-width: 0px;
  border-radius: 10px;
  color:#ffffff;
}

.aboutus-image {
  /* height: 0%; */
  width: 85%;
  size: cover;
  border-radius:10px;
}

.aboutUs-image3{
  height: 0%;
  width: 50%;
  size: cover;
  
}
.aboutUs-image2{
   width:70%;
   height:70%;
}

.about-head {
  font-family: Roboto;
  font-size: 35px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 0px;
  text-align:center;
}

.image-container {
  text-align: center;

  padding-right: 50px;
  height: fit-content;
}

.text-container {
  text-align: left;
  font-size:14px;
  padding-right: 30px;
  font-weight: 300;
  font-family: Roboto;
  height: fit-content;
}

/* home aboutus styling ends here*/

/* home Refer friend styling starts here*/
.refer-style {
  background-color: #ffffff;
}

.refer-text {
  font-weight: 300;
  font-family: Roboto;
  height: fit-content;
  padding-left: 50px;
  text-align: left;
}

.refer-image {
  text-align: center;

  padding-left: 60px;
  height: fit-content;
}

.refer-image2{
  height: fit-content;
}

/* home Refer friend styling ends here*/

.aboutPage {
  /* width: 100%; */
  background-color: #ffffff;
 
  min-height: 400px;
}



/* download styling starts */

/* .buttons{
  height:20%;
  width:100%;
} */

@media screen and (min-width: 768px) {
  .buttons{
    height:30%;
    width:100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .buttons{
    height:100%;
    width:100%;
  }
}
.ios-button{
  background-color:aqua;
  
  color:black
}

.andriod-button{
  
  background-color: rgb(100, 237, 132);
  
}

.ios-button:hover{
  background-color:aqua;
  color:black
}

.andriod-button:hover{
  background-color: rgb(100, 237, 132);
  
}

/* footer styling*/
.footer {
  justify-Content: space-between;
  display: flex;
  flex-direction: row;
  background-color:rgb(226, 247, 224);
  padding: 15px 0;



}

.footer-color-changer {
  color: #808080;
  /* padding: 20px 20px; */
}




#root {
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100vh;
}

/* tags styling*/




/*navbar styling*/


/*home styling*/
.home-image {
  width: 50%;
  height: 50%;
  margin: 20px 0px 0px 0px;
  size: cover;
}


h3 {
  margin: 10px 10px;
}

/* .naving{
  position:fixed;
  width:100%;
  z-index:1000000;
  top:0;
} */
/* Terms & conditions style starts here */
.main-container{
  margin:100px auto 100px auto;
}
.policy-headh4{
  
  color:#737373;
}
.policy-head{
  color:#666666;
}
.policy-para{
  color:#8c8c8c;
}
.ol-terms {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* Terms & conditions style ends here */

.text-alignment{
  text-align: center;
}
.font-bold{
  font-family: Helvetica;
  font-size: 30px;
  font-weight: bold;
}
.mini-font-bold{
  font-family: Helvetica;
  font-size: 25px;
  font-weight: bold;
}
.remove-ul{
  list-style-type: none;
  font-size: 24px;
  font-family: helvetica;
}

.para-size{
  font-size:21px;
  font-family: helvetica;
}

.flex-design{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}